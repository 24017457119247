<template>
  <div class="main">
    <section v-for="img in imgList">
      <img :src="img" alt="" />
    </section>
  </div>
</template>

<script>
  export default {
    name: 'ContentManagementRules',
    data() {
      return {
        imgList: [
          'https://static1.kaixinyf.cn/img/lza63171afd8bbb4934972149.png',
          'https://static1.kaixinyf.cn/img/lza63171b04ce5ae082544654.png',
          'https://static1.kaixinyf.cn/img/lza63171b0c1600a256286963.png',
          'https://static1.kaixinyf.cn/img/lza63171b136c2e1751315699.png',
          'https://static1.kaixinyf.cn/img/lza63171b1b0cab1131437349.png',
          'https://static1.kaixinyf.cn/img/lza63171b2696aac431538280.png',
          'https://static1.kaixinyf.cn/img/lza63171b2d4f710858378618.png',
        ],
      };
    },
    mounted() {
      document.title = '开心音符内容安全管理办法';
    },
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #eff0f1;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eff0f1;
  }
  section {
    margin-bottom: 18px;
  }
  section img {
    max-width: 980px;
  }
</style>
